import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { AppService } from "@shared/services/app.service";
import { map, of, tap } from "rxjs";

export const countriesResolver: ResolveFn<boolean> = (route, state) => {
  const _AppService = inject(AppService);
  if (_AppService.countries) return of(true);

  return _AppService.getAllCountries().pipe(
    tap((response) => {
      _AppService.countries = response.data.countries;
      _AppService.setCountryFlags();
    }),
    map(() => true)
  );
};
