import { createReducer, on } from '@ngrx/store';
import { brandAndIdentityAction, dateAndTimingsAction, focusInputExistsAction, formatAndLocationAction, wizardCurrentStepAction } from './actions.store';
import { FormGroup } from '@angular/forms';
import { brandIdentity, dateAndTimings, formatAndLocation } from '@plan/events/types/event';

export const focusInputExistsInitialState: boolean = false;
export const brandAndIdentityInitialState: brandIdentity = {} as brandIdentity;
export const formatAndLocationInitialState: formatAndLocation = {} as formatAndLocation;
export const dateAndTimingsInitialState: dateAndTimings = {} as dateAndTimings;
export const wizardCurrentStepInitialState: number = 0;


/**
 * @description Reducers for the focus input exists
 */
export const focusInputExistsReducer = createReducer(
    focusInputExistsInitialState,
    on(focusInputExistsAction.focusInputExist, (_state, { focusInputExist }) => {
        return focusInputExist
    })
);

/**
 * @description Reducers for the brand and identity in the first step of creating event
 */
export const brandAndIdentityReducer = createReducer(
    brandAndIdentityInitialState,
    on(brandAndIdentityAction.brandAndIdentity, (_state, { brandAndIdentity }) => {
        return brandAndIdentity
    })
);

/**
 * @description Reducers for the format and location in the second step of creating event
 */
export const formatAndLocationReducer = createReducer(
    formatAndLocationInitialState,
    on(formatAndLocationAction.formatAndLocation, (_state, { formatAndLocation }) => {
        return formatAndLocation
    })
);

/**
 * @description Reducers for the date and timings in the third step of creating event
 */
export const dateAndTimingsReducer = createReducer(
    dateAndTimingsInitialState,
    on(dateAndTimingsAction.dateAndTimings, (_state, { dateAndTimings }) => {
        return dateAndTimings
    })
);

export const wizardCurrentStepReducer = createReducer(
    wizardCurrentStepInitialState,
    on(wizardCurrentStepAction.wizardCurrentStep, (_state, { wizardCurrentStep }) => {
        return wizardCurrentStep
    })
);
