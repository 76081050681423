import { Route } from '@angular/router';
import { MainGuard } from '../guards/main.guard';
import { industrySectorResolver } from '@shared/resolvers/industry-sector.resolver';
import { HasOrganizationGuard } from '../guards/has-organization.guard';

export const PlanRoutes: Route[] = [
  {
    path: 'planning',
    children: [
      {
        path: 'create-organization',
        loadComponent: () =>
          import(
            './organization/create-organization-profile/components/create-organization/create-organization.component'
          ).then((m) => m.CreateOrganizationComponent),
        resolve: { industrySectors: industrySectorResolver },
      },
      {
        path: 'organization-settings',
        loadComponent: () =>
          import(
            './organization/settings/organization-settings.component'
          ).then((m) => m.OrganizationSettingsComponent),
        children: [
          {
            path: 'public-info',
            loadComponent: () =>
              import(
                './organization/settings/components/public-info/public-info.component'
              ).then((m) => m.PublicInfoComponent),
          },
          {
            path: 'legal-info',
            loadComponent: () =>
              import(
                './organization/settings/components/legal-info/legal-info.component'
              ).then((m) => m.LegalInfoComponent),
            resolve: { industrySectors: industrySectorResolver },
          },
          {
            path: 'manage-team',
            loadComponent: () =>
              import(
                './organization/settings/components/manage-team/manage-team.component'
              ).then((m) => m.ManageTeamComponent),
          },
          {
            path: 'billing',
            loadComponent: () =>
              import(
                './organization/settings/components/billing-page/billing-page.component'
              ).then((m) => m.BillingPageComponent),
          },
          {
            path: '',
            redirectTo: 'public-info',
            pathMatch: 'full',
          },
        ],
        canActivate: [HasOrganizationGuard],
      },
      {
        path: 'events',
        loadComponent: () =>
          import('./events/events.component').then((m) => m.EventsComponent),
        resolve: { industrySectors: industrySectorResolver },
        canActivate: [HasOrganizationGuard],
      },
      {
        path: 'events/:eventId',
        loadComponent: () =>
          import(
            './events/components/event-wizard/event-wizard.component'
          ).then((m) => m.EventWizardComponent),
        resolve: { industrySectors: industrySectorResolver },
        canActivate: [HasOrganizationGuard],
      },
      {
        path: 'events/:eventId/personas',
        loadComponent: () =>
          import('./personas/personas.component').then(
            (m) => m.PersonasComponent
          ),
        canActivate: [HasOrganizationGuard],
      },
      {
        path: 'events/:eventId/personas/:personaId',
        loadComponent: () =>
          import(
            './personas/components/persona-wizard/persona-wizard.component'
          ).then((m) => m.PersonaWizardComponent),
        canActivate: [HasOrganizationGuard],
      },
      {
        path: 'events/:eventId/setup/:personaId',
        loadComponent: () =>
          import(
            './events/components/landing-page/landing-page.component'
          ).then((m) => m.LandingPageComponent),
        canActivate: [HasOrganizationGuard],
      },
    ],
    canActivate: [MainGuard],
  },
];
