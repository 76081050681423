import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AppService } from '@shared/services/app.service';
import { map, of, tap } from 'rxjs';

export const industrySectorResolver: ResolveFn<boolean> = (route, state) => {
  const _AppService = inject(AppService);
  _AppService.loadGoogleMaps();
  if (_AppService.industrySectors) return of(true);
  return _AppService.getIndustrySectorList().pipe(
    tap((response) => {
      _AppService.industrySectors = response.data.industry_sectors;
    }),
    map(() => true)
  );
};
