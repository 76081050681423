import { Routes } from '@angular/router';
import { AuthRoutes } from '@auth/routes';
import { SettingsRoutes } from './settings/routes';
import { PlanRoutes } from '@plan/routes';
import { countriesResolver } from '@shared/resolvers/countries.resolver';
import { EventsRoutes } from './events/routes';

export const routes: Routes = [
  {
    path: '',
    resolve: { countries: countriesResolver },
    children: [
      ...AuthRoutes,
      ...PlanRoutes,
      ...SettingsRoutes,
      ...EventsRoutes,
      { path: '**', redirectTo: 'cross-road' },
    ],
  },
  { path: '**', redirectTo: 'cross-road' },
];
