import { Route } from '@angular/router';

export const EventsRoutes: Route[] = [
  {
    path: 'events/:orgSlug/:eventSlug/:personaSlug',
    loadComponent: () =>
      import(
        '../plan/registration/components/form-components/registration-form/registration-form.component'
      ).then((m) => m.RegistrationFormComponent),
  },
  {
    path: 'events/:orgSlug/:eventSlug/:personaSlug/confirmation',
    loadComponent: () =>
      import(
        '../plan/registration/components/on-screen-confirmation/on-screen-confirmation.component'
      ).then((m) => m.OnScreenConfirmationComponent),
  },
];
