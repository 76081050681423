<section
  class="flex flex-col items-center m-auto h-screen"
  [ngClass]="{ 'bg-gray-50': showGreyBackground }"
>
  @if(showNavbar){
  <app-navbar class="flex justify-center"></app-navbar>
  }
  <div
    class="flex-1 w-full overflow-x-hidden hide-overflow"
    [ngClass]="{'!mt-12': showNavbar}"
    [style.maxWidth]="enableFullWidth ? 'none' : '1440px'"
  >
    <router-outlet></router-outlet>
  </div>
</section>
