import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '@auth/services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MainGuard {
  /**
   * @constructor Creates an instance of MainGuard
   * @param _AuthService AuthService
   */
  constructor(private _AuthService: AuthService, private _Router: Router) { }

  /**
   * @method Checks if the user is authorized to access the route or not
   * @returns Observable<boolean>
   */
  canActivate(): Observable<boolean> {
    const token = this._AuthService.token;
    const authorized = !!token;
    const tokenExpired = token
      ? token?.lastLoggedIn + token?.expires_in * 60 * 1000 < Date.now()
      : true;
    if (!this._AuthService.token) {
      this._Router.navigate(['/auth']);
      return of(false);
    }
    if (authorized && !tokenExpired) return of(true);
    else {
      return this._AuthService.refreshToken().pipe(
        map((response) => {
          if (response?.data?.token?.access_token) {
            this._AuthService.saveUserCredentials(
              this._AuthService.storage,
              response.data
            );
            return true;
          } else {
            this._AuthService.signOut();
            return false;
          }
        }),
        catchError(() => {
          this._AuthService.signOut();
          return of(false);
        })
      );
    }
  }
}
