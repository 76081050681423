import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { Route } from '@angular/router';
import { MainGuard } from '../guards/main.guard';
import { AuthGuard } from './guards/auth.guard';

export const AuthRoutes: Route[] = [
  {
    path: 'auth',
    loadComponent: () =>
      import('./auth.component').then((m) => m.AuthComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'cross-road',
    loadComponent: () =>
      import('./components/cross-road/cross-road.component').then(
        (m) => m.CrossRoadComponent
      ),
    canActivate: [MainGuard],
  },
  {
    path: 'lock-account',
    loadComponent: () =>
      import('./components/lock-account/lock-account.component').then(
        (m) => m.LockAccountComponent
      ),
  },
  {
    path: 'access-denied',
    loadComponent: () =>
      import('./components/access-denied/access-denied.component').then(
        (m) => m.AccessDeniedComponent
      ),
  },
];
