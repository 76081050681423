import { FormGroup } from '@angular/forms';
import { createActionGroup, props } from '@ngrx/store';
import { brandIdentity, dateAndTimings, formatAndLocation } from './../../../types/event';


/**
 * @description Actions for the focus input exists
 */
export const focusInputExistsAction = createActionGroup({
    source: 'Focus Input Exists',
    events: {
        'Focus Input Exist': props<{ focusInputExist: boolean }>(),
    },
});

/**
 * @description Actions for the brand and identity in the first step of creating event
 */
export const brandAndIdentityAction = createActionGroup({
    source: 'Brand and Identity',
    events: {
        'Brand and Identity': props<{ brandAndIdentity: brandIdentity }>(),
    },
})

/**
 * @description Actions for the format and location in the second step of creating event
 */
export const formatAndLocationAction = createActionGroup({
    source: 'Format and Location',
    events: {
        'Format and Location': props<{ formatAndLocation: formatAndLocation }>(),
    },
})

/**
 * @description Actions for the date and timings in the third step of creating event
 */
export const dateAndTimingsAction = createActionGroup({
    source: 'Date and Timings',
    events: {
        'Date and Timings': props<{ dateAndTimings: dateAndTimings }>(),
    },
})

/**
 * @description Actions for the current step of the wizard
 */
export const wizardCurrentStepAction = createActionGroup({
    source: 'Wizard Current Step',
    events: {
        'Wizard Current Step': props<{ wizardCurrentStep: number }>(),
    }
})