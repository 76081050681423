import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';

export const AuthGuard: CanActivateFn = () => {
  const _AuthService = inject(AuthService);
  const _Router = inject(Router);
  if (_AuthService.user && _AuthService.token) {
    _Router.navigate(['cross-road']);
    return false;
  }
  return true;
};
