import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { NavbarComponent } from '@shared/components/navbar/navbar.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [NavbarComponent, RouterOutlet, NgClass],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  public showNavbar = false;
  public routes = ['/auth', '/cross-road','/lock-account'];
  public enableFullWidth: boolean = false
  public showGreyBackground: boolean = false;
  private _fullWidthRoutes = ['/auth', '/cross-road'];


  /**
   * @constructor Creates an instance of AppComponent and inject dependencies
   * @param _AuthService AuthService
   * @param _Router Router
   */
  constructor(
    private _AuthService: AuthService,
    private _Router: Router,
  ) {}

  /**
   * @method ngOnInit
   * @description Lifecycle hook that is called after data-bound properties of a directive are initialized.
   * @returns void
   */
  ngOnInit(): void {
    this._Router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd){
        this.showNavbar = !this.routes.some(route => event.urlAfterRedirects.startsWith(route)) && this.isLoggedIn();
        this.enableFullWidth = this._fullWidthRoutes.some(route => event.urlAfterRedirects.match(route));
        // Extract status and search term from the URL
        const urlParams = new URLSearchParams(event.urlAfterRedirects.split('?')[1]);
        const status = urlParams.get('status');
        const searchTerm = urlParams.get('search');
        // Determine if the grey background should be shown
        this.showGreyBackground = this._shouldShowGreyBackground(status, searchTerm,event.urlAfterRedirects);}
      });
    }

  /**
   * @method _shouldShowGreyBackground
   * @description Determine if the grey background should be shown
   * @param status string | null
   * @param searchTerm string | null
   * @returns boolean
   */
  private _shouldShowGreyBackground(status: string | null, searchTerm: string | null, route: string): boolean {
    const validStatuses = ['ALL', 'LIVE', 'UPCOMING', 'FINISHED', 'DRAFT'];
    const isPlanningEventsRoute = route === '/planning/events';
    return validStatuses.includes(status || '') || !!searchTerm || isPlanningEventsRoute;
  }

  /**
   * @method isLoggedIn
   * @description Check if user is logged in or not
   * @returns boolean
   */
  public isLoggedIn(): boolean {
    return !!this._AuthService.user;
  }
}
