import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AppService } from '@shared/services/app.service';
import { map, of, tap } from 'rxjs';

export const currenciesResolver: ResolveFn<boolean> = (route, state) => {
  const _AppService = inject(AppService);
  if (_AppService.currencies) return of(true);

  return _AppService.getAllCurrencies().pipe(
    tap((response) => {
      _AppService.currencies = response.data.currencies;
    }),
    map(() => true)
  );
};
