import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { OrganizationService } from '@plan/organization/services/organization.service';

@Injectable({
  providedIn: 'root',
})
export class HasOrganizationGuard {
  /**
   * @constructor Creates an instance of HasOrganizationGuard
   * @param _OrganizationService OrganizationService
   */
  constructor(private _Router: Router, private _OrganizationService: OrganizationService) { }

  /**
   * @method Checks if the user has current organization or not
   * @returns Observable<boolean>
   */
  canActivate(): Observable<boolean> {
    const currentOrganization = this._OrganizationService.currentOrganization;
    if (!currentOrganization?.id) {
      this._Router.navigate(['/planning/create-organization']);
      return of(false);
    }
    return of(true)
  }
}
