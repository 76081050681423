import { Route } from '@angular/router';
import { MainGuard } from '../guards/main.guard';
import { industrySectorResolver } from '@shared/resolvers/industry-sector.resolver';
export const SettingsRoutes: Route[] = [
  {
    path: 'settings',
    children: [
      {
        path: 'profile',
        loadComponent: () =>
          import('./profile/profile-settings.component').then(
            (m) => m.ProfileSettingsComponent
          ),
        canActivate: [MainGuard],
        resolve: { industrySectors: industrySectorResolver },
      },
      {
        path: '**',
        redirectTo: 'profile',
      },
    ],
  },
];
